.logoWebImage {
  max-height: 50px;
  max-width: 225px;
  padding-top: 5px;
  padding-bottom: 8px;
}

.logoMobileImage {
  max-height: 45px;
  max-width: 215px;
  padding-top: 5px;
  padding-bottom: 8px;
}
